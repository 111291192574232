import React from 'react';

function ContactForm() {
    return (
        <section className="contact" id="contact">
            <div name="contact"><h2>Contact me</h2></div>
            <form action="https://getform.io/f/5d0339e9-8a22-479d-a689-5a4364b47588" method="POST">
                <div>
                    <div className="contact_message">
                        <label htmlFor="contact_message">Message</label>
                        <textarea id="contact_message" type="text" name="message"/>
                    </div>
                    <div className="contact_fields">
                        <label htmlFor="contact_name">Name</label>
                        <input id="contact_name" type="text" name="name"/>
                        <label htmlFor="contact_email">Email</label>
                        <input id="contact_email" type="email" name="email"/>
                    </div>
                </div>
                <button type="submit">Send</button>
                <input type="hidden" id="captchaResponse" name="g-recaptcha-response"/>
            </form>
        </section>
    )
}

export default ContactForm;